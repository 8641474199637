*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

#logo {
    position: absolute;
    z-index: 999;
    width: 300px;
    left: 30px;
    top: 30px;
}
#welcome {
    pointer-events: none;
    position: absolute;
    z-index: 999;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    left: 0px;
    top: 0px;
    object-fit: contain;
}

#welcome img {
    width: 1800px;
    max-width: 100%;
    object-fit: contain;
}

canvas{
    background: linear-gradient(222deg, #2559af, #463ea0);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 30s ease infinite;
    -moz-animation: AnimationName 30s ease infinite;
    animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
@keyframes AnimationName {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}